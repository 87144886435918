import React, { useState, useEffect } from 'react';

import { Link, navigate } from 'gatsby';

import Carousel from 'react-multi-carousel';

import "assets/styles/pages/viewmore-initiativecard.scss";

// import iniativepic1 from 'assets/images/initiative/What-Are-Plants.png';

import treedate from "assets/images/initiative/tree-date.png";

import pathtree from "assets/images/map-initiative/Path 22295.png";

import moment from "moment";

import homeicon from "assets/images/icons/icon-md-home.svg";
import Breadrightarrow from "assets/images/icons/bread-arrow-right.png";
import Backleftarrow from "assets/images/icons/back-arrow-left.svg";
import { toast, ToastContainer } from "react-toastify";
import { doLogout } from "components/Auth";
// import PropTypes from "prop-types";
 
export default function Viewinitiativecard(props) {
  const plantationData = props?.data;
  
  const [loopData, setLoopData] = useState([]);
  const responsive = {
    desktop: {
      breakpoint: {
        max: 30000,
        min: 320,
      },
      items: 1,
    },
  };
  useEffect(() => {
    if (loopData !== undefined) {
      let propsData = plantationData;
      if (propsData !== null) {
        setLoopData([propsData.plantationTrackingDetails]);
      } else {
        toast.error("Authentication failed");
        setTimeout(function () {
          doLogout();
          navigate("/");
        }, 3000);
      }
    }
  }, []);
  const navigateToHome = () => {
    navigate("/trackaSapling");
  };
  const ButtonGroup = () =>
    // { next, previous, ...rest }
    {
      return (
        <div className="carousel-button-group">
          {/* <div className='navigations'>
          <button onClick={() => previous()} className='me-3'>
            <i className='icon icon-arrow-left-short'></i>
          </button>
          <button onClick={() => next()}>
            <i className='icon icon-arrow-right-short'></i>
          </button>
        </div> */}
        </div>
      );
    };

  const trackNow = (data) => {
    let viewData = data;
    navigate("/map-initiative", { state: { viewData, props } });
  };

  return (
    <>
      <div className="register-breadcumb pad60">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="bread-title-bar">
                <span className="hand-pointer">
                  <img
                    src={homeicon}
                    alt="treeRightIconGreen"
                    onClick={navigateToHome}
                  />
                </span>
                <span className="bread-right-arrow-small">
                  <img src={Breadrightarrow} alt="treeRightIconGreen" />
                </span>
                <h3 className="bread-title-text">
                  {" "}
                  {plantationData &&
                    plantationData.plantationTrackingDetails &&
                    plantationData.plantationTrackingDetails[0]
                      .projectName}{" "}
                </h3>
              </div>
              {/* <p>{props.data && props.data.projectName} </p> */}
            </div>
            <div className="col-md-3 text-right back-btn-pad">
              <Link to="/trackaSapling" className="btn btn-viewmore">
                <span className="backarrow-left">
                  <img src={Backleftarrow} alt="Back arrow" />
                </span>
                Back
              </Link>
            </div>
            {/* <div className='col-md-2'>
              <Link className='btn btn-back' to="/trackaSapling"> Back </Link>
            </div> */}
          </div>
        </div>
      </div>
      {plantationData && plantationData.plantationTrackingDetails ? (
        <div className="intro-carousel">
          <Carousel
            arrows={true}
            autoPlaySpeed={3000}
            draggable
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            renderButtonGroupOutside={true}
            swipeable
            responsive={responsive}
            customButtonGroup={<ButtonGroup />}
          >
            {plantationData &&
              plantationData.plantationTrackingDetails &&
              plantationData.plantationTrackingDetails.map((item) => {
                return (
                  <div className="img-wrapper" key={item.id}>
                    <div className="initiativecard-wrpper-grid">
                      <div className="container">
                        <div className="row">
                          <div
                            className="col-md-12 initiative-card-grid pad-left0"
                            key={item.id}
                          >
                            <div className="wid-30">
                              <div>
                                <div className="intro-carousel mg-bottom20 mg-top10">
                                  <Carousel
                                    arrows={true}
                                    autoPlaySpeed={3000}
                                    draggable
                                    keyBoardControl
                                    minimumTouchDrag={80}
                                    partialVisible
                                    renderButtonGroupOutside={true}
                                    swipeable
                                    responsive={responsive}
                                    customButtonGroup={<ButtonGroup />}
                                  >
                                    {item.imagesLog.map((imageitem) => {
                                      return (
                                        <div
                                          className="img-wrapper"
                                          key={imageitem.id}
                                        >
                                          <img
                                            data-src={imageitem.imageUrl}
                                            className="lazyload"
                                            alt="path tree"
                                          />
                                        </div>
                                      );
                                    })}
                                  </Carousel>
                                </div>
                                <div className="card custom-cards col-md-12 col-sm-12">
                                  <div className="card-body1 h-300">
                                    <button
                                      type="button"
                                      className="btn btn-white"
                                    >
                                      {item.species.speciesName} Tree
                                    </button>
                                    <h6 className="cloudy dot-line">
                                      {item.growthStatus.attributeMap
                                        ? item.growthStatus.attributeMap.height
                                        : 0}{" "}
                                      cm
                                    </h6>

                                    {/* <img src={pathtree} className='img-fluid cent-items' alt='path tree' /> */}
                                    <div className="cent-items">
                                      <img
                                        data-src={pathtree}
                                        className="lazyload"
                                        alt={item.alt}
                                      />
                                    </div>
                                  </div>
                                  <div className="card-body dark-black he-250"></div>
                                </div>
                              </div>
                            </div>

                            <div className="iniativecard-body wid-70">
                              <div className="iniativecard-title">
                                {item.projectName}
                              </div>
                              <h5>{plantationData?.mappedProject?.[0].name}</h5>
                              <div className="green-clr sapling-text">
                                {plantationData.donatedTrees} Saplings
                              </div>

                              <p className="text-para">
                                Donation Id # {plantationData.certificateId}
                              </p>

                              <p className="date-text">
                                {" "}
                                <span>
                                  {" "}
                                  <img
                                    src={treedate}
                                    className="img-fluid treedate-icon"
                                    alt="Tree Date"
                                  />{" "}
                                </span>{" "}
                                {moment(item.plantationDate).format(
                                  "ddd, MMM D YYYY"
                                )}
                              </p>

                              <div className="tree-blocks-wrpper">
                                <div className="row">
                                  <div className="col-sm-5 display-tree-item">
                                    <div>
                                      <h6 className="card-text">Trees</h6>
                                      <h6 className="cloudy">
                                        {item.mappedSaplingCount} &nbsp;
                                        {item.species.speciesName}
                                        {/* {item.plantationTrackingDetails && item.plantationTrackingDetails.map(data => {
                                      return <b><span className='text-city'>{data.mappedSaplingCount} {data.species.speciesName} &nbsp;&nbsp;</span></b>
                                    })} */}
                                      </h6>
                                    </div>

                                    {/* <div className=''>
                                  <h6 className='card-text'>
                                    {truncate(item.trees, {
                                      length: 100,
                                      omission: '...',
                                    })}
                                  </h6>
                                  <h6 className='cloudy'>
                                    {truncate(item.bayantree, {
                                      length: 100,
                                      omission: '...',
                                    })}
                                  </h6>
                                </div> */}
                                  </div>
                                  <div className="col-sm-5 display-tree-item">
                                    <div>
                                      <h6 className="card-text">Planted By</h6>
                                      <h6 className="cloudy">
                                        {item.plantedBy}
                                      </h6>
                                    </div>

                                    {/* <div className=''>
                                  <h6 className='card-text'>
                                    {truncate(item.donatedtree, {
                                      length: 100,
                                      omission: '...',
                                    })}
                                  </h6>
                                  <h6 className='cloudy'>
                                    {truncate(item.treeitem, {
                                      length: 100,
                                      omission: '...',
                                    })}
                                  </h6>
                                </div> */}
                                  </div>
                                  <div className="col-sm-2 display-tree-item"></div>
                                  <div className="col-sm-5 display-tree-item">
                                    <div>
                                      <h6 className="card-text">Planted On</h6>
                                      <h6 className="cloudy">
                                        {moment(item.plantationDate).format(
                                          "ddd, MMM D YYYY"
                                        )}
                                      </h6>
                                    </div>
                                    {/* <div>
                                  <h6 className='card-text'>
                                    {truncate(item.co, {
                                      length: 100,
                                      omission: '...',
                                    })}
                                  </h6>
                                  <h6 className='cloudy'>
                                    {truncate(item.kg, {
                                      length: 100,
                                      omission: '...',
                                    })}
                                  </h6>
                                </div> */}
                                  </div>

                                  {/* <div className='col-sm-5 display-tree-item'>
                                  <h6 className='card-text'>
                                    Location
                                  </h6>
                                  <h6 className='cloudy'>
                                    {item.city}
                                  </h6>
                                </div>  */}
                                  <div className="col-sm-2 display-tree-item"></div>
                                  {/* <div className='col-sm-11'>
                                <p className='pad-20-0'>
                                  {' '}
                                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                  has been the industry's standard dummy text ever since the 1500s, when an unknown
                                  printer took a galley of type and scrambled it to make a type specimen book.
                                </p>
                              </div> */}
                                </div>
                              </div>
                              <br />
                              <button
                                type="button"
                                className="btn btn-tracknow"
                                onClick={() => {
                                  trackNow(item);
                                }}
                              >
                                Track Now
                              </button>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      ) : null}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />
    </>
  );
}

// Viewinitiativecard.propTypes = {
//   data: PropTypes.objectOf(PropTypes.object()).isRequired,
// };