import React from 'react';

import Layout from 'layout/index';

import SEO from 'components/seo/index';

import Viewinitiativecard from 'components/initiative/view-more-initiative-card';

// markup
const Initiativecard = ({ location }) => {
  const dataValue = (location.state && location.state.viewData !== undefined) ? location.state.viewData : null;
  return (
    <>
      <SEO title='Forests By Heartfulness | Initiative card' />
      <Layout>
        <section id='initiativecard'>
          <Viewinitiativecard data={dataValue} />
        </section>
      </Layout>
    </>
  );
};

export default Initiativecard;
